<template>
    <div class="app-container">
        <div data-v-5c12baef="" class="column">
            <div class="column_wrap" v-for="item in list" :key="item.id">
                <div class="column_wrap_img">
                    <img :src="item.image" alt="" />
                </div>
                <h2>{{ item.title }}</h2>
                <p v-html="item.description"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        list: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped lang="scss">
.column {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;

    .column_wrap + .column_wrap {
        margin-left: 30px;
    }

    &_wrap {
        width: 100%;

        &_img {
            width: 100%;
            height: 300px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h2 {
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            margin-top: 32px;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            margin-top: 24px;
        }
    }
}

@media screen and (max-width: 900px) {
    .column {
        flex-direction: column;
    }
    .column .column_wrap + .column_wrap {
        margin-left: 0;
    }
}

@media screen and (max-width: 500px) {
    .column_wrap_img img {
        object-fit: contain;
    }
}
</style>
